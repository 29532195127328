import styled from "styled-components";

export const ListingCardHolder = styled.div`
  padding: 0px;
  margin-right: -8px;
  max-width: 860px;

  @media (max-width: 1023px) {
    margin-right: 0px;
  }

  @media (min-width: 769px) {
    max-width: 95%;
  }
`;

export const ListingCardHolderGrid = styled.div`
  display: ${(props) => (props.showMap ? "grid" : "grid")};
  margin-right: ${(props) => (props.showMap ? "-8px" : "0px")};
  max-width: ${(props) => (props.showMap ? "min(50vw, 812px)" : "auto")};
  gap: 24px;

  @media (min-width: 0px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr)) !important;
  }

  @media (min-width: 551px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
  }

  @media (min-width: 878px) {
    grid-template-columns: ${(props) => (props.showMap ? "repeat(1, minmax(0px, 1fr)) !important" : "repeat(3, minmax(0px, 1fr)) !important")};
  }
  @media (min-width: 1232px) {
    row-gap: 8px !important;
    grid-template-columns: ${(props) => (props.showMap ? "repeat(2, minmax(0px, 1fr)) !important" : "repeat(4, minmax(0px, 1fr)) !important")};
  }

  @media (min-width: 1607px) {
    grid-template-columns: ${(props) => (props.showMap ? "repeat(2, minmax(0px, 1fr)) !important" : "repeat(5, minmax(0px, 1fr)) !important")};
  }

  
  /* display: flex;
	flex-wrap: wrap;
	grid-column-gap: 32px;
	justify-content: flex-start; */
  /* padding: 0px;
	margin-right: -8px;
	max-width: 860px;

	@media(max-width: 1023px) {
		margin-right: 0px;
	}

	@media(min-width: 769px) {
		max-width: 95%;
	} */
`;

export const PriorityListingHolderGrid = styled.div`
  display: ${(props) => (props.showMap ? "grid" : "grid")};
  margin-right: ${(props) => (props.showMap ? "-8px" : "0px")};
  max-width: ${(props) => (props.showMap ? "min(50vw, 812px)" : "auto")};
  gap: 24px;

  @media (min-width: 0px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr)) !important;
  }

  @media (min-width: 551px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
  }

  @media (min-width: 878px) {
    grid-template-columns: ${(props) => (props.showMap ? "repeat(1, minmax(0px, 1fr)) !important" : "repeat(3, minmax(0px, 1fr)) !important")};
  }
  @media (min-width: 1232px) {
    row-gap: 8px !important;
    grid-template-columns: ${(props) => (props.showMap ? "repeat(1, minmax(0px, 1fr)) !important" : "repeat(4, minmax(0px, 1fr)) !important")};
  }

  @media (min-width: 1607px) {
    grid-template-columns: ${(props) => (props.showMap ? "repeat(1, minmax(0px, 1fr)) !important" : "repeat(5, minmax(0px, 1fr)) !important")};
  }
  `;
  
export const HeaderHolder = styled.div`
  font-size: 32px;
  line-height: 36px;
  font-size: 22px;
  line-height: 30px;
  color: rgb(34, 34, 34);
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.staticStatus && props.staticHeader ? "row" : "row"};
  margin-top: 20px;
  justify-content: space-between;
  align-items: ${(props) =>
    props.staticStatus && props.staticHeader ? "flex-start" : "center"};
  flex-wrap: wrap;
  margin-bottom: 12px;
  grid-row-gap: 12px;

  @media (min-width: 769px) {
    /* padding: 20px 10px 0px; */
    padding: 0px 10px;
    /* max-width: 95%; */
  }
`;
export const MainHeader = styled.h1`
  color: inherit;
  font-size: 1em;
  font-weight: inherit;
  line-height: inherit;
  margin: 0px;
  padding: 0px;
  @media (max-width: 580px) {
    margin-bottom: 7px;
  }
`;
export const HeaderStaticDesc = styled.h2`
  margin-top: 6px;

  @media (min-width: 769px) {
    padding-left: 10px;
  }
`;
export const MainHolder = styled.section`
  display: flex;
  width: 100%;
  margin-left: 96px;

  @media (max-width: 1023px) {
    margin-left: 0px;
  }
`;

export const StaticHolder = styled.div`
  height: auto;
  min-width: auto;
  // position: static;
  width: 100%;

  // display: flex;
  // flex-direction: column;
`;

export const FilterHolder = styled.div`
  width: 100%;
  position: fixed;
  top: 60px;
  z-index: 3;
  scrollbar-width: none;
  padding-left: 0px;

  @media (max-width: 1200px) {
    overflow-x: auto;
    /* 96 to account for the side panel */
    width: calc(100vw - 96px);
  }

  @media (max-width: 1023px) {
    padding-left: 0px;
    width: 100vw;
  }
  
  @media (max-width: 830px) {
    top: 112px;
  }
  @media (max-width: 600px) {
    scrollbar-width: auto;
  }
`;

export const FilterShell = styled.div`
  background: white;
  border-bottom: 1px solid #dadce0;
  position: relative;
  width: 100%;
  z-index: 3;
  
  @media (max-width: 1200px) {
    overflow-y: hidden;
    width: max-content;
  }
`;
export const TopFiltersHolder = styled.form`
  /* overflow: hidden; */
  padding: 8px 0;
  display: flex;
  align-items: center;
  display: flex;
  scrollbar-width: none;
  overflow-x: visible;

  @media (max-width: 1200px) {
    padding: 8px 10px 14px;
    width: max-content;
  }
`;

export const ResultsAndMap = styled.div`
  position: relative;
`;

export const FixedSearcherHolder = styled.div`
  position: fixed;
  z-index: 5;
  right: 0;
  left: ${props => props.pro ? "210px" : "170px"};
  width: max-content;
  display: flex;
  justify-content: center;
  top:10px;
  
  @media (max-width: 830px) {
    left: 0;
    top: 60px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

`
export const FixedSearcherMaxW = styled.div`
  max-width: 830px;
  width: 40vw;

  @media (max-width: 830px) {
    width: 100%;
    padding: 0px 5px;
  }
`

// Media rules on this
// gpcwnc
export const Results = styled.div`
  opacity: 1;
  overflow-x: visible;
  overflow-y: visible;
  padding-right: 0;
  position: static;
  transition: opacity 0.5s;
  width: ${(props) => (props.showMap ? "min(50vw, 812px)" : "100%")};
  padding: 65px 0px 0px;

  @media (max-width: 769px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 830px) {
    padding-top: 120px;
  }
`;
// Media rults hkndPe
export const Map = styled.div`
  bottom: 0;
  height: calc(100vh - 120px);
  left: auto;
  overflow: hidden;
  padding: 0;
  position: fixed;
  right: 0;
  top: auto;
  width: calc(100% - min(50vw,812px) - 96px - 16px);

  /* @media (max-width: 1023px) {
    width: 50vw;
  } */
  @media (max-width: 768px) {
    width: 100%;
  }s
`;

export const SpinnerHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const ListingsSpinnerHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-height: calc(100vh - 280px);
`;

export const RangeAlert = styled.p`
  font-weight: 400;
  color: #9c8f8f;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
`;
export const FooterResultsCount = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
`;

export const PaginationButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

export const RemoveBoundaryHolder = styled.div`
  position: absolute;
  top: 0px;
  margin-top: 20px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
`;

export const RemoveBoundaryInner = styled.div`
  white-space: nowrap;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  padding: 5px 14px 5px;
  background-color: white;
  border: 2px solid #125f3f;
  font-size: 15px;
`;
export const DescriptionWrapper = styled.div`
  font-size: 0.8rem;
  margin-top: 16px;
  line-height: 1rem;
`;

export const AreaSearchHolder = styled.div`
  position: relative;

  @media (max-width: 1200px) {
    min-width: 190px;
  }
`;

export const SmallFancyLink = styled.a`
  font-weight: 500;
  font-family: "Jarkarta", sans-serif;
  font-size: .85rem;
  color: #125f3f;
`;

export const SmallCount = styled.p`
  font-size: 12px;
  color: gray;
`;

export const TopHeader = styled.div`
  font-family: "Jarkarta", sans-serif;
  font-size: 24px;
  letter-spacing: -0.3px;
  line-height: 1.3;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const SSDetails = styled.p`
  font-size: 0.825rem;
  font-family: "Jarkarta", sans-serif;
  font-weight: 400;
`;

export const ModalBodyText = styled.p`
  font-size: 1rem;
  font-family: "Jarkarta", sans-serif;
  margin-bottom: 2rem;
  line-height: 1.75;
`;

export const Floater = styled.div`
  position: fixed;
  top: 10px;
  left: ${(props) => (props.pro ? "190px" : "170px")};
  z-index: 50;
  width: calc(100vw - 170px - 312px);
  max-width: 30rem;
`;

export const Suggestions = styled.div`
  /* position: absolute;
	top: 3rem;
	width: 30rem;
	left: 0px; */
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  font-size: 1rem;
  overflow: hidden;
  width: 100%;
  min-width: 270px;
  padding: 8px 0;
`;

export const StyledRow = styled.div`
  border: none;
  position: relative;
  color: #8c8c8c;
  font-size: 12px;
  min-height: 24px;
  padding-top: 6px;
  padding-bottom: 7px;
  cursor: pointer;
  text-align: left;
  background: ${(props) => (props.active ? "#f1f3f4" : "#fff")};

  &:hover {
    background: #f1f3f4;
  }
`;

export const ContentCell = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const SpacerCell = styled.div`
  flex-shrink: 1;
  padding-right: 13px;
`;

export const IconSpan = styled.span`
  float: left;
  height: 20px;
  width: 20px;
  margin: 2px 26px 0 18px;
`;

export const TextSpan = styled.span`
  /* color: #333; */
  font-size: 12px;
  color: #70757a;
`;

export const BoldSpan = styled.span`
  font-size: 13px;
  color: #000;
  font-weight: 500;
  margin-right: 0.25rem;
`;

export const HeaderLine = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 28px 0px 3px;
  padding: 0px;
  // font-weight: 100;
  font-family: "Jarkarta", sans-serif;
`;

export const FilterHeader = styled.p`
  font-size: 18px;
  line-height: 1.8rem;
  margin: 10px 0px;
  font-weight: 500;
  font-family: "Jarkarta", sans-serif;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const IncrementHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 24px;
  margin: 1px;
  position: absolute;
  right: 0px;
  z-index: 1;
  height: calc(100% - 2px);

  & svg {
    width: 0.6em;
    height: 0.6em;
  }
`;

export const PaddedOption = styled.option`
  padding: 8px 0px;
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-gap: 0px 10px; 

  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(3, 1fr)
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr)
  }
`